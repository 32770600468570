module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-gba/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.gba-qualitaetssiegel.com","siteId":"005c8392-af44-46be-89e2-ff18083169f6","branch":"master","algoliaSearchApiKey":"","algoliaApplicationId":"","context":"production"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
